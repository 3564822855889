.container-zapzap-logo {
  position: fixed;
  bottom: 0;
  z-index: 999;
  right: 5px;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 15px;
  }
  img {
    cursor: pointer;
    filter: drop-shadow(1px 3px 23px #000000);
    width: 70px;
    object-fit: contain;
    transition: 0.8s;
    transform: scale(1);

    &:hover {
      transition: 0.4s;
      transform: scale(1.1);
    }
    @media (max-width: 768px) {
      width: 60px;
    }
  }
}
