.container-sobre {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.container-sobre-banner {
  margin-top: 90px;
  width: 100%;
  height: 80vh;
  position: relative; /* Tornando o posicionamento relativo para os pseudo-elementos */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container-sobre-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../Images/Sobre.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(5px);

  @media (max-width: 768px) {
    filter: blur(3px);
  }
}

.container-sobre-banner::after {
  /* Pseudo-elemento para a camada preta */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Cor preta com 50% de opacidade */
}
.container-sobre-banner > * {
  position: relative;
  z-index: 1;
}

.container-sobre-inf {
  width: var(--width-desktop);
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-inline: 5px;

  @media (max-width: 1300px) {
    width: var(--width-not);
  }
  @media (max-width: 768px) {
    width: var(--width-mobile);
  }

  h1 {
    margin-bottom: 10px;
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.363));
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 5px solid var(--cor-primaria);
  }

  p {
    width: 90%;
    font-weight: 400;
    color: #ffffff;
    font-size: var(--font-text);

    @media (max-width: 768px) {
      width: var(--width-mobile);
      font-size: var(--font-text-mobile);
    }
  }
}

.container-sobre-fundamentos {
  width: var(--width-desktop);
  padding-block: 200px;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 1300px) {
    width: var(--width-not);
  }
  @media (max-width: 768px) {
    padding-block: 80px;
    width: var(--width-mobile);
    flex-direction: column;
  }

  .container-sobre-fundamentos-isbox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    padding-block: 10px;
    gap: 20px;

    img {
      width: 70px;
      object-fit: contain;
    }

    h2 {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: var(--font-subtitle);

      @media (max-width: 768px) {
        font-size: var(--font-subtitle-mobile);
      }
    }
    p {
      text-align: start;
      width: 100%;
      line-height: 22px;
      font-size: var(--font-text);
      font-weight: 300;
      @media (max-width: 768px) {
        font-size: var(--font-text-mobile);
      }
    }
  }
}

.container-sobre-parallax {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Tornando o posicionamento relativo para os pseudo-elementos */
  width: 100%;
  height: 400px;
  background-image: url("../../Images/Banner2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.container-sobre-parallax::before {
  /* Pseudo-elemento para a camada escura */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63); /* Cor preta com 50% de opacidade */
}
.container-sobre-parallax-inf {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: var(--width-desktop);
  gap: 40px;
  padding-inline: 5px;

  @media (max-width: 1300px) {
    width: var(--width-not);
  }
  @media (max-width: 768px) {
    width: var(--width-mobile);
  }

  h2 {
    width: 100%;
    text-align: center;
    color: #ffffff;
    line-height: 32px;
    font-size: var(--font-subtitle-mobile);
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: var(--font-subtitle-mobile);
    }
  }

  button {
    width: 400px;
    border: none;
    padding: 10px;
    font-size: var(--font-text);
    font-weight: 700;
    border-radius: 5px;
    background-color: var(--cor-primaria);
    color: #ffffff;
    transition: 0.6s;
    transform: scale(1);

    @media (max-width: 768px) {
      width: 250px;
      font-size: var(--font-text-mobile);
    }

    &:hover {
      transition: 0.4s;
      background-color: #ffffff;
      color: var(--cor-primaria);
      transform: scale(1.1);
    }
  }
}
