.container-servico {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 90px;
}

.container-servico-segmentos {
  width: var(--width-desktop);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 90px;

  @media (max-width: 1300px) {
    width: var(--width-not);
  }
  @media (max-width: 768px) {
    padding-block: 50px;
    width: var(--width-mobile);
  }

  h1 {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.363));
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 5px solid var(--cor-primaria);
    text-align: center;
  }
}

.container-servico-segmentos-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 40px;
  flex-wrap: wrap;
  gap: 25px;
}
.container-servico-segmentos-isbox {
  min-width: 32%;
  flex: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  gap: 10px;
  width: 30%;
  height: 500px;

  cursor: pointer;
  transition: 0.9s;

  @media (max-width: 768px) {
    min-width: 100%;
  }
  &:hover {
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  h2 {
    font-size: var(--font-subtitle);
    color: var(--cor-primaria);
    font-weight: 500;
    text-align: center;
    width: 100%;
    padding-block: 15px;

    @media (max-width: 768px) {
      font-size: var(--font-subtitle-mobile);
    }
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }
}
.container-servico-servicos {
  width: var(--width-desktop);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 50px;

  @media (max-width: 1300px) {
    width: var(--width-not);
  }
  @media (max-width: 768px) {
    width: var(--width-mobile);
    padding-block: 30px;
  }

  h1 {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.363));
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 5px solid var(--cor-primaria);
    text-align: center;
  }
}

.container-servico-servicos-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 40px;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 768px) {
    padding-block: 20px;
  }
}

.container-servico-servicos-isbox {
  min-width: 32%;
  flex: 1;
  height: 170px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.7s;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-text);
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: var(--font-text-mobile);
    }
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: 0.5s;
  }
}

.container-servico-servicos-isbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../Images/Servicos.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: blur(8px);
  border-radius: 10px;
}

.container-servico-servicos-isbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.692);
  border-radius: 15px;
}
.container-servico-servicos-isbox > * {
  position: relative;
  z-index: 1;
}
