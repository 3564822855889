.container-preload {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.849); /* Fundo semi-transparente */
  overflow: hidden;
  transition: 0.5s;
  z-index: 999;

  img {
    animation: girar 4s linear infinite;
    width: 50%;
    height: 50%;
    object-fit: contain;

    @media (max-width: 768px) {
      width: 70%;
      height: 70%;
    }
  }
}

@keyframes girar {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
