@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 35s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 14));
    transition: 0.5s;
  }
}

// Styling
.slider {
  height: 200px;
  display: flex;
  align-items: center;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 200px;
    position: absolute;
    width: 50px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 28);

    @media (max-width: 768px) {
      width: calc(150px * 28);
    }
  }

  .slide {
    height: auto;
    width: 250px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      width: 150px;
    }
    img {
      object-fit: contain;
      height: auto;
      width: 200px;
      @media (max-width: 768px) {
        width: 110px;
      }
    }
  }
}
