.container-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #080404;
  padding-top: 40px;

  p {
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
  }
}

.container-footer-section {
  width: var(--width-desktop);
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 25px;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    width: var(--width-not);
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: var(--width-mobile);
    gap: 40px;
  }
}

.container-footer-section-localizacao {
  h3 {
    width: 100%;
    padding-block: 3px;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
  }

  img {
    width: 150px;
    object-fit: contain;
  }
}

.container-footer-section-institucional {
  gap: 15px;
  h2 {
    width: 100%;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 16px;
  }

  a {
    width: 100%;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 16px;
  }
}
.container-footer-section-contato {
  gap: 15px;
  h2 {
    width: 100%;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 16px;
  }

  p {
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 16px;
  }
}
.container-footer-section-redes {
  gap: 15px;
  .container-footer-section-redes-links {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  h2 {
    width: 100%;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 16px;
  }

  img {
    width: 30px;
    object-fit: contain;
  }
}
.container-footer-section-redes,
.container-footer-section-contato,
.container-footer-section-institucional,
.container-footer-section-localizacao {
  flex: 1;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
    min-width: 100%;

    h2,
    p,
    h3,
    a {
      text-align: center;
    }
  }
}
