.Container-Header {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  position: fixed;
  transition: background-color 0.3s ease;
  background-color: #fff;
  z-index: 999;
}

.Container-Header.scrolled {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.51);
}

.Content-Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;
}

.Logo {
  img {
    width: 150px;
    height: 80px;
    object-fit: contain;
  }
  width: 30%;
}

.Menu-Links {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;

  .nochecked {
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-size: var(--font-text);
    font-family: var(--font-montserrat);
    font-weight: 600;
    line-height: 16px;
    transition: 0.6s;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;

    &:hover {
      color: var(--cor-primaria);
      border-bottom: 3px solid var(--cor-primaria);
      transition: 0.3s;
      padding-bottom: 10px;
    }
  }
  .checked {
    color: var(--cor-primaria);
    border-bottom: 3px solid var(--cor-primaria);
    transition: 0.3s;
    padding-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .Menu-Links {
    opacity: 0;
    transition: 1s ease, opacity 1s ease; // Adiciona transição para left e opacity
    left: -100%;
    top: 0;
    position: fixed;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
  }

  .openMenu-Links {
    gap: 40px;
    opacity: 1;
    top: 0;
    position: fixed;
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-block: 100px;
    justify-content: start;
    flex-direction: column;
    padding-inline: 25px;
    left: 0;
    background-color: rgb(27, 26, 26);
    transition: left 0.3s ease, opacity 0.3s ease; // Adiciona transição para left e opacity
    .nochecked {
      color: #fff;
    }
    .checked {
      color: var(--cor-primaria);
      border-bottom: 3px solid var(--cor-primaria);
      transition: 0.3s;
      padding-bottom: 10px;
    }
  }
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #000000;
  margin: 8px 0; /* Aumentei um pouco a margem para melhor visualização */
  transition: 0.4s;
}

.open .bar1 {
  transform: rotate(-45deg) translate(-9px, 9px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  transform: rotate(45deg) translate(-6px, -7px);
}

@media (min-width: 768px) {
  .Menu-Sanduiche {
    display: none;
  }
}
