@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: var(--font-montserrat);
}

:root {
  --cor-primaria: #1c54b7;
  --cor-secundaria: #383838;
  --cor-background-primario: #f6f6f6;
  --cor-background-secundario: #979797;
  --font-montserrat: "Montserrat", sans-serif;
  --font-title: 35px;
  --font-title-mobile: 22px;
  --font-subtitle: 24px;
  --font-subtitle-mobile: 20px;
  --font-text: 18px;
  --font-text-mobile: 14px;
  --width-desktop: 80%;
  --width-not: 90%;
  --width-mobile: 97%;
}

html,
body,
#root {
  height: 100%;
  background-color: var(--cor-background-primario);
}
a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

h1 {
  text-align: start;
  font-weight: 700;
  color: var(--cor-primaria);
  font-size: var(--font-title);
  padding-block: 5px;
  @media (max-width: 768px) {
    font-size: var(--font-title-mobile);
  }
}
