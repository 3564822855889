.container-contato {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 90px;
  padding-block: 50px;
  h1 {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.363));
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 5px solid var(--cor-primaria);
    text-align: center;
  }
}

.container-contato-content {
  width: var(--width-desktop);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 50px;

  @media (max-width: 1300px) {
    padding-block: 30px;
    width: var(--width-not);
  }
  @media (max-width: 768px) {
    width: var(--width-mobile);
  }
}

.container-contato-inf {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-around;
  padding-block: 50px;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-block: 20px;
  }
}
.container-contato-contato,
.container-contato-redes,
.container-contato-zapzap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  h2 {
    text-align: center;
    width: 100%;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: var(--font-subtitle);

    @media (max-width: 768px) {
      font-size: var(--font-subtitle-mobile);
    }
  }

  p {
    color: rgb(0, 0, 0);
    font-weight: 400;
    font-size: var(--font-text);
    @media (max-width: 768px) {
      font-size: var(--font-text-mobile);
    }
  }

  img {
    object-fit: contain;
    width: 50px;
  }
}

.container-contato-zapzap {
  a {
    padding: 10px;
    background-color: var(--cor-primaria);
    color: rgb(248, 248, 248);
    font-weight: 600;
    font-size: var(--font-text);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    width: 200px;
    border-radius: 10px;
    transition: 0.5s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

    &:hover {
      transition: 0.3s;
      box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
        0 5px 14px 0 rgba(0, 0, 0, 0.18);
    }
    img {
      object-fit: contain;
      width: 30px;
    }
  }
}
