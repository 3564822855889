.home-banner {
  margin-top: 90px;
  width: 100%;
  height: 500px;
  background-image: url("../../Images/BANNER\ VR.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.container-home-sobre {
  padding-block: 150px;
  width: var(--width-desktop);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1300px) {
    padding-block: 50px;
    width: var(--width-not);
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: var(--width-mobile);
  }

  .container-home-sobre-text {
    width: 60%;

    @media (max-width: 768px) {
      width: var(--width-mobile);
    }

    div {
      width: 15%;
      height: 5px;
      background-color: var(--cor-primaria);
    }

    p {
      width: 90%;
      font-weight: 400;
      color: #000;
      font-size: var(--font-text);
      @media (max-width: 768px) {
        width: var(--width-mobile);
        font-size: var(--font-text-mobile);
      }
    }
  }
  .container-home-sobre-imagem {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      display: none;
    }
    img {
      width: 80%;
      height: auto;
      object-fit: cover;
    }
  }
}

.container-home-fluxo {
  padding-block: 50px;
  width: var(--width-desktop);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1300px) {
    width: var(--width-not);
  }

  @media (max-width: 768px) {
    width: var(--width-mobile);
  }
  h1 {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.363));
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 5px solid var(--cor-primaria);
  }

  .section-home-fluxo {
    padding-block: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 15px;

    @media (max-width: 1400px) {
      gap: 50px;
      justify-content: center;
    }

    @media (max-width: 1000px) {
      padding-block: 50px;
      justify-content: center;
      gap: 10px;
    }
    @media (max-width: 500px) {
      flex-direction: column;
    }

    .section-home-fluxo-isbox {
      max-width: 33%;
      min-width: 23%;
      min-height: 550px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: column;
      border-radius: 10px;
      padding: 15px;
      transition: 0.6s;
      img {
        width: 90px;
        height: auto;
        object-fit: contain;
        padding-block: 10px;
      }
      @media (max-width: 1400px) {
        max-width: 47%;
        min-width: 47%;
        img {
          width: 60px;
        }
      }
      @media (max-width: 900px) {
        min-height: auto;
        padding-block: 30px;
      }
      @media (max-width: 768px) {
        max-width: 47%;
        min-width: 47%;
      }
      @media (max-width: 500px) {
        max-width: 95%;
        min-width: 95%;
      }

      &:hover {
        cursor: pointer;
        transition: 0.4s;
        box-shadow: 1px 1px #1c55b738, 2px 2px #1c55b738, 3px 3px #1c55b738,
          4px 4px #1c55b738, 5px 5px #1c55b738, 6px 6px #1c55b738,
          2px 2px #1c55b738;
        transform: translateX(-3px);
      }

      h2 {
        width: 100%;
        font-weight: 500;
        font-size: var(--font-subtitle);
        text-align: center;
        padding-block: 15px;

        @media (max-width: 768px) {
          font-size: var(--font-subtitle-mobile);
        }
      }

      p {
        width: 100%;
        font-weight: 5000;
        font-size: var(--font-text);
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 7px;
        padding-block: 15px;

        @media (max-width: 768px) {
          width: var(--width-mobile);
          font-size: var(--font-text-mobile);
        }
        img {
          width: 20px;
          object-fit: contain;
          padding-block: 0px;
        }
      }
    }
  }
}

.container-home-carrosel {
  width: 100%;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
  h1 {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.363));
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 5px solid var(--cor-primaria);
  }
}

.container-home-galeria {
  width: var(--width-desktop);
  padding-block: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1300px) {
    padding-block: 50px;
    width: var(--width-not);
  }

  @media (max-width: 768px) {
    width: var(--width-mobile);
  }
  h1 {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.363));
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 5px solid var(--cor-primaria);
  }

  .container-home-galeria {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    .galeria-img {
      width: 24%;
      flex: 1;
      border-radius: 4px;
      object-fit: cover;
      height: 450px;
      @media (max-width: 1300px) {
        width: 33%;
      }
      @media (max-width: 768px) {
        width: var(--width-not);
      }
    }
  }
}
